.background-content {
  background-image: rgb(255, 255, 255);
  /* background-attachment: scroll; */
  padding-top: 15px;
  min-height: calc(85vh - 4px);
  max-width: 100%;
  width: 100%;
}

.bloc-result {
  background-color: rgb(255, 255, 255);
  color: #000000;
}
.active {
  background-color: rgb(153, 153, 153);
}

.border {
  border: 4mm groov #126ba8;
}

.text-policy {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.87);
}

.under-construction {
  text-align: center;
  height: 50px;
  color: darkred;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 25px;
  margin-top: 1rem;
}

.menu-text-policy {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 15px;
  color: rgba(255, 255, 255);
}

.menu-text-donate-policy {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 15px;
  color: rgb(0, 0, 0);
}

.footer-text-policy {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 13px;
  color: rgba(255, 255, 255);
}

.no-margin {
  margin-right: 0.2rem !important;
}

.ui.dropdown > .dropdown.icon {
  margin-left: 0.2rem !important;
}

.Site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.Site-content {
  flex: 1;
}

.inverted-text-color {
  color: rgb(255, 255, 255);
}
